<template>
    <hr class="context-menu-line">
</template>

<script lang="ts">
import { Component, VueComponent } from "@simonbackx/vue-app-navigation/classes";

@Component
export default class ContextMenuLine extends VueComponent {}
</script>
